<div class="signContainer">
  <div class="are">
    <div class="left">
      <div class="title">{{'COMMON.RESET_YOUR_PASSWORD' | translate}}</div>
      <div class="texte">
        {{'COMMON.ENTER_THE_EMAIL_ADDRESS_ASSOCIATED_WITH' | translate}}
      </div>
      <form [formGroup]="forgotForm" (ngSubmit)="forgotFormSubmit()" class="form">
        <mat-form-field class="mt-3" hideRequiredMarker="true" style="width: 100%;">
          <input matInput type="email" placeholder="{{'COMMON.EMAIL'|translate}}" formControlName="email">
        </mat-form-field>
        <button mat-raised-button type="submit" class="whiteTextButton" color="primary"
          [disabled]="!forgotForm.valid">{{'COMMON.CONTINUE' | translate}}</button>
      </form>

      <div class="items">
        <a class="link strong" routerLink="/">
          {{'COMMON.RETURN_TO_SIGN_IN'| translate}}</a>
      </div>
    </div>

    <div class="right background-tint">
      <div class="top">
        <img src="assets/img/logow.png" width="150" height="32" />
      </div>
      <div class="texte">
        Design software for custom-made jewelry
      </div>
      <div class="bottom">
      </div>
    </div>
  </div>




</div>