import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';



@Injectable({
  providedIn: 'root'
})
export class MessageService {

  duration = 3000;

  constructor(private snackBar: MatSnackBar, private translate: TranslateService) { }

  showErrorMessage(errorMessage: string) {
    this.translate.get(errorMessage).subscribe((res: string) => {
      this.snackBar.open(res, '', {
        duration: this.duration,
      });
    });

  }

  showMessage(message: string, value = null) {
    this.translate.get(message, { valor: value }).subscribe(res => {
      this.snackBar.open(res, '', {
        duration: this.duration,
      });
    });
  }

  showPlanIsNotValid() {

  }

  parseMessage(message: string, longMessage = false): string {

    let messageOut: string;

    if (message && message.startsWith('@')) {
      const toParse = message.split('#')[1];
      const valuesArray = toParse.split('_');

      const n_stock = valuesArray[0];
      const n_needed = valuesArray[1];
      const carats = valuesArray[2];
      const gemType = valuesArray[3];

      if (longMessage) {

        messageOut = `There are ${n_stock} of ${n_needed} ${gemType} with${carats} required`;
      } else {
        messageOut = `${n_needed} ${gemType} ${carats} Required`;
      }

    } else {

      messageOut = `ERRORS.BREAKDOWN.${message}`;
    }

    return messageOut;
  }


  showErrorMessageNicely(errorMessage: string) {

    const res = this.translate.instant('ERRORS.' + errorMessage);
    const snackBarRef = this.snackBar.open(res, 'Help', {
      duration: 3000,
      verticalPosition: 'bottom'
    });

    snackBarRef.onAction().subscribe(() => {
      window.open("https://docs.2shapes.com/2shapes-design/introduction", "_blank");
    });
  }


}
