<div class="signContainer">
  <div class="are">
    <div class="left">
      <div class="title">{{'COMMON.LOGIN' | translate}}</div>
      <form [formGroup]="loginForm" (ngSubmit)="loginFormSubmit()" class="form" [ngClass]="{
                      'has-error':
                        (loginForm.get('email').touched || loginForm.get('email').dirty) &&
                        !loginForm.get('email').valid}">
        <mat-form-field hideRequiredMarker="true" style="width: 100%;">
          <mat-label>{{'COMMON.EMAIL' | translate}}</mat-label>
          <input matInput type="email" placeholder="{{'COMMON.EMAIL' | translate }}" formControlName="email" />
        </mat-form-field>

        <mat-form-field hideRequiredMarker="true" style="width: 100%;">
          <mat-label>{{'COMMON.PASSWORD' | translate}}</mat-label>

          <input matInput [type]="showPassword ? 'text' : 'password'" placeholder="{{'COMMON.PASSWORD' | translate }}"
            autocomplete="on" formControlName="password" />
          <mat-icon matSuffix (click)="togglePasswordVisibility()">{{showPassword?'visibility_off':'visibility'}}
          </mat-icon>



        </mat-form-field>
        <a class="smallLink" routerLink="/password/reset"> {{ 'COMMON.FORGOT_YOUR_PASSWORD'
          | translate
          }}</a>

        <button mat-raised-button class="whiteTextButton" type="submit" color="primary">
          {{'COMMON.CONTINUE'| translate }}
        </button>

        <div class="items">
          {{'COMMON.DONT_HAVE_AN_ACCOUNT' | translate}}
          <a class="link strong" routerLink="/signup">{{'COMMON.SIGN_UP' | translate}}</a>
        </div>

      </form>

    </div>

    <div class="right background-tint">
      <div class="top">
        <img src="assets/img/logow.png" width="150" height="32" />
      </div>
      <div class="texte">
        Design software for custom-made jewelry
      </div>
      <div class="bottom">
        {{ version }}
      </div>
    </div>
  </div>




</div>