import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import pkg from '../../../../../../../package.json';
import { PasswordTools } from '../../../shared/models/password.tools';
import { MessageService } from '../../../shared/services/message.service';
import { UserService } from '../../../shared/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.sass']
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  showSpinner: boolean;
  showPasswordChecks: boolean;
  checkLength: boolean;
  checkNumbersAndLetters: boolean;
  checkSpecialChars: boolean;
  countries = [];
  public showPassword: boolean = false;
  ipAddress = '';

  version: string;

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
  constructor(private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    public translate: TranslateService,
    private fb: UntypedFormBuilder,
    public snackBar: MatSnackBar,
    private messageService: MessageService,
    private http: HttpClient,
  ) {
    this.showSpinner = false;

    this.version = pkg.version;


    this.translate.use(this.translate.getBrowserLang());
    this.getIPAddress();

    this.route.params.forEach((params: Params) => {
      const lang = params['lang'];

      if (this.translate.langs.includes(lang)) {
        this.translate.use(lang);
      }

      if (this.translate.langs.includes(lang)) {
        this.translate.use(lang);
      }



    }).then();

    this.userService.existUserAndIsValidInBackend().subscribe(res => {
      //  this.hideFullLoading = !res;
      if (res == true) {


        if (this.router.url.length > 3) {

          this.router.navigate([
            this.router.url
              .replace(' ', '')
              .replace('/?url=', '')
              .replace(/%2F/g, '/')
          ]);
        } else {

          if (this.userService.user.hasAccessToDesign()) {
            this.router.navigate(['/design']);
            return;
          }

          if (this.userService.user.hasAccessToBoutique()) {
            this.router.navigate(['/pos']);
            return;
          }

          if (this.userService.user.hasAccessToCRM()) {
            this.router.navigate(['/crm']);
            return;
          }

          if (this.userService.user.hasAccessToInventory()) {
            this.router.navigate(['/inventory']);
            return;
          }
          if (this.userService.user.hasAccessToWorkshop()) {
            this.router.navigate(['/workshop']);
            return;
          }

          if (this.userService.user.hasAccessToDashboard()) {
            this.router.navigate(['/dashboard']);
            return;
          }

        }
      }

    });
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(3)]]
    });
  }
  getIPAddress() {

    try {
      this.http.get<{ ip: string }>('https://api.ipify.org/?format=json')
        .subscribe(
          data => {
            if (data && data.hasOwnProperty('ip')) {
              this.ipAddress = data['ip'];
            }
          },
          () => {

          });
    } catch (error) {
    }
  }
  loginFormSubmit() {
    const email = this.loginForm.get('email').value;
    const password = PasswordTools.SHA1(this.loginForm.get('password').value);
    this.showSpinner = true;

    this.userService.login(email, password, this.ipAddress).subscribe(
      () => {

        if (this.userService.user.dark_mode) {
          document.documentElement.setAttribute('theme', 'dark');
        }
        if (this.router.url.replace('login', '').length > 3) {

          let ruta = this.router.url
            .replace(' ', '')
            .replace('/login?url=', '')
            .replace('signup', '')
            .replace('login', '')
            .replace(/%2F/g, '/');


          this.router.navigate([
            ruta
          ]).then();
        } else {
          if (this.userService.user.organization != null) {
            this.router.navigate(['/design']).then();
          }
        }
      },
      err => {

        this.showSpinner = false;

        if (err?.message == 'INVALID_SUBSCRIPTION') {
          this.messageService.showMessage('MESSAGE_CONTROL.LOGIN.INVALID_SUBSCRIPTION');
          return;
        }
        switch (err?.error?.message) {
          case 'USER_INVALID': {
            //     this.messageService.showMessage('MESSAGE_CONTROL.LOGIN.INVALID_USER');
            break;
          }

          case 'USER_IS_NOT_ACTIVE': {
            //    this.messageService.showMessage('MESSAGE_CONTROL.LOGIN.USER_IS_NOT_ACTIVE');
            break;
          }

          case 'USER_IS_DISABLED': {
            //   this.messageService.showMessage('MESSAGE_CONTROL.LOGIN.USER_IS_DISABLED');
            break;
          }
          case 'USER_IS_NOT_SUPERUSER': {
            //   this.messageService.showMessage('MESSAGE_CONTROL.LOGIN.USER_IS_NOT_SUPERUSER');
            break;
          }
          case 'INVALID_SUBSCRIPTION': {
            //   this.messageService.showMessage('MESSAGE_CONTROL.LOGIN.INVALID_SUBSCRIPTION');
            break;
          }

          default: {
            //   this.messageService.showMessage('MESSAGE_CONTROL.LOGIN.USER_OR_PASSWORD_WRONG');
            break;
          }

        }

      },
      () => {
      }
    );
  }
}
