import { AgmCoreModule } from '@agm/core';
import { ClipboardModule } from '@angular/cdk/clipboard';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import '@angular/common/locales/global/pl';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  Injectable,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BasicModule } from '@iishapes/basic';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ROUTES } from './app.routes';
import { LoginComponent } from './entry/components/login/login.component';
import { PasswordResetDoneComponent } from './entry/components/password-reset-done/password-reset-done.component';
import { PasswordResetComponent } from './entry/components/password-reset/password-reset.component';
import { PasswordSetComponent } from './entry/components/password-set/password-set.component';
import { SignupDoneComponent } from './entry/components/signup-done/signup-done.component';
import { SubscriptionProblemComponent } from './entry/components/subscription-problem/subscription-problem.component';
import { SuperuserComponent } from './entry/components/superuser/superuser.component';
import { TryComponent } from './entry/components/try/try.component';
import { ValidateComponent } from './entry/components/validate/validate.component';
import { GlobalErrorHandler } from './entry/interceptors/global-error-handler';
import { HttpConfigInterceptor } from './entry/interceptors/httpconfig.interceptor';
import { AuthguardService } from './shared/services/authguard.service';
import { ListsService } from './shared/services/lists.service';
import { LoadingService } from './shared/services/loading.service';
import { UserService } from './shared/services/user.service';
import { SharedModule } from './shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/i18n/iishapes_', suffix: '.json' },
  ]);
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private userService: UserService) { }
  handleError(error) {
    console.error('--->', error);
    if (this.userService.user !== null && this.userService.user.email) {
      Sentry.setUser({ email: this.userService.user.email });
    }

    if (error.status && error.status === 400) {
      return;
    }
    if (error.status && error.status === 403) {
      return;
    }

    if (error.status && error.status === 404) {
      return;
    }

    if (error.status && error.status === 500) {
      return;
    }

    if (error.status && error.status === 0) {
      return;
    }

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error.message && chunkFailedMessage.test(error.message)) {
      window.location.reload();
      return;
    }
    const err = error.originalError || error.error || error;
    if (err) {
      Sentry.captureException(err);
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TryComponent,
    ValidateComponent,
    PasswordResetComponent,
    PasswordSetComponent,
    SubscriptionProblemComponent,
    SuperuserComponent,
    SignupDoneComponent,
    PasswordResetDoneComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    BasicModule,
    // WidgetsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    MatOptionModule,
    MatTabsModule,
    MatSelectModule,
    MatSliderModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatIconModule,
    MatButtonModule,
    ClipboardModule,
    MatBadgeModule,
    RouterModule.forRoot(ROUTES, { useHash: false }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBAMbTLnrtSRewCkFV7dTY05uwZ9-xPj3E',
      libraries: ['places'],
    }),
    SharedModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  providers: [
    UserService,
    ListsService,
    AuthguardService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    LoadingService,
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: 'googleTagManagerId', useValue: 'GTM-NP5W67X' },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
